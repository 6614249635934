import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { setCookie } from './js/utils'
import remAndPhoneConfig from './toolJs/remConfig'

import { Toast } from 'vant'
Vue.use(Toast)

remAndPhoneConfig()
window.onresize = remAndPhoneConfig

//使用香港语言
import { Locale } from 'vant'
import zhHK from 'vant/lib/locale/lang/zh-HK'
Locale.use('zh-HK', zhHK)

import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  Loading,
  NumberKeyboard,
  Row,
  Col,
  Divider,
} from 'vant'

Vue.use(NavBar)
  .use(Progress)
  .use(Swipe)
  .use(SwipeItem)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Area)
  .use(DatetimePicker)
  .use(Cell)
  .use(CellGroup)
  .use(Loading)
  .use(NumberKeyboard)
  .use(Row)
  .use(Col)
  .use(Divider)

import 'vant/lib/index.css'

import './assets/css/public_qjc.css' //公用css
Vue.config.productionTip = false

//如果为PC打开则跳转至PC端

import wx from 'weixin-js-sdk'

//檢測token是否有效  检测是否完善信息
import { hasToken, agentInfo, wxShare } from '@/js/axios'

setCookie('username', '123')

router.beforeEach((to, from, next) => {
  //更改標題
  document.title = to.meta.title

  // 是否为疫境求真代理人后台
  var isAgent = /^\/agent/.test(to.fullPath)

  // 登录检测
  if (to.meta.needLogin) {
    if (isAgent) {
      // 疫境求真代理人平台
      if (store.state.agentLogin) {
        if (/^\/agent\/admin/.test(to.fullPath)) {
          // 后台账户只能进入后台
          if (localStorage.getItem('agentType') == 10) {
            next()
          } else {
            // 否则进入前台首页
            next({
              path: '/agent',
            })
          }
        } else {
          // 后台进入非后台跳转至后台首页
          if (localStorage.getItem('agentType') == 10) {
            next({
              path: '/agent/admin',
            })
          } else {
            next()
          }
        }
      } else {
        next({
          path: '/agent/login',
          query: {
            toUrl: to.fullPath,
          },
        })
      }
    } else {
      // 香港体检
      if (store.state.isLogin) {
        //token是否失效(香港体检适用)
        hasToken().then((res) => {
          if (res.status == 1) {
            next()
          } else {
            store.dispatch('exit')
            next({
              path: '/login',
              query: {
                toUrl: to.fullPath,
              },
            })
          }
        })
      } else {
        next({
          path: '/login',
          query: {
            toUrl: to.fullPath,
          },
        })
      }
    }
  } else {
    next()
  }
})

//页面跳转顶头开始
router.afterEach((to, from, next) => {
  setTimeout(() => {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      try {
        // 微信分享
        wxShare({
          url: location.href,
        }).then(function(result) {
          // console.log(result);
          //需在用户可能点击分享按钮前就先调用
          wx.config({
            debug: result.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId, // 必填，公众号的唯一标识
            timestamp: result.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.noncestr, // 必填，生成签名的随机串
            signature: result.signature, // 必填，签名，见附录1
            jsApiList: result.apiList.split(','), // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })
          // 'updateAppMessageShareData','updateTimelineShareData','onMenuShareWeibo'
          // console.log(wx);

          var m_title = result.sharTile
          var m_desc = result.shareDesc
          var m_link = result.shareLink
          var m_img = result.shareImg

          wx.ready(function() {
            wx.updateAppMessageShareData({
              title: m_title, // 分享标题
              desc: m_desc, // 分享描述
              link: m_link, // 分享链接
              imgUrl: m_img, // 分享图标
              success: function(res) {
                // 用户确认分享后执行的回调函数
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              },
            })
            wx.updateTimelineShareData({
              title: m_title, // 分享标题
              desc: m_desc, // 分享描述
              link: m_link, // 分享链接
              imgUrl: m_img, // 分享图标
              type: '', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: function(res) {
                // 用户确认分享后执行的回调函数
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              },
            })
            wx.onMenuShareWeibo({
              title: m_title, // 分享标题
              desc: m_desc, // 分享描述
              link: m_link, // 分享链接
              imgUrl: m_img, // 分享图标
              success: function(res) {
                // 用户确认分享后执行的回调函数
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              },
            })
          })
        })
      } catch (e) {
        return false
      }
    }
  }, 300)
  window.scrollTo(0, 0)
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
