import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  isLogin: localStorage.getItem('access_token')?true:false,// 香港体检
	  agentLogin: localStorage.getItem('agent_token')?true:false// 疫境求真代理人平台
  },
  mutations: {
	  //荷载为true或false,是否登录
	  checkLogin (state, isLog){
		  state.isLogin = isLog;
	  },
	  
	  //疫境求真代理人平台
	  checkAgentLogin (state, isLog){
		  state.agentLogin = isLog;
	  }
  },
  actions: {
	  // 香港体检
	  login ({ commit }, token){
		  localStorage.setItem('access_token',token);
		  commit('checkLogin',true);
	  },
	  exit ({ commit }){
		  localStorage.removeItem('access_token');
		  localStorage.removeItem('user');
		  localStorage.removeItem('userInfo');
		  commit('checkLogin',false)
		  ;
	  },
	  
	  toquestion(){
		  
	  },
	  
	  // 疫境求真代理人平台
	  agentLogin ({ commit }, token){
		  localStorage.setItem('agent_token',token);
		  commit('checkAgentLogin',true);
	  },
	  agentExit ({ commit }){
		  localStorage.removeItem('agent_token');
		  localStorage.removeItem('agentType');
		  commit('checkAgentLogin',false);
	  }
  },
  modules: {}
});
